module.exports = {
  mock: [
    {
      text: "Exemplo search type", // Nome que aparecerá para escolher
      value: {
        type: "searchtype", // Tipo de uso do filtro
        SP: "SP_CE360903010", // SP que o dialogo localiza usará
        opcoesTipo: [
          // Valores na tabela e filtro dialogo localiza
          {
            value: "NF730_CD_NPS",
            text: "Cd. NCM",
            type: ["text"],
          },
          {
            value: "NF730_DS_NPS",
            text: "Ds. NCM",
            type: ["text"],
          },
        ],
        nome: "Cabeçalho dialog exemplo search type", // Cabeçalho no dialogo localiza
        adicionar: false, // Habilita o botão de criar um novo registro no dialogo localiza
        filtro: true, // Aciona o filtro no dialogo localiza
        itens: false, // Array de itens caso seja autocomplete/ combobox
        objKey: "nf730_cd_nps", // Chave do valor que você queira colocar na linha
      },
    },
    {
      text: "Exemplo auto complete",
      value: {
        type: "autocomplete",
        SP: false,
        opcoesTipo: "ce110_cd_situac",
        nome: false,
        adicionar: false,
        filtro: false,
        itens: [
          { text: "Ativo", value: 1 },
          { text: "Bloqueado", value: 2 },
        ],
        objKey: false,
      },
    },

    {
      text: "Exemplo v-money",
      value: {
        type: "monetary",
        SP: false,
        opcoesTipo: "ce110_vl_compra",
        nome: false,
        adicionar: false,
        filtro: false,
        itens: false,
        objKey: false,
      },
    },
  ],
};
