<template>
  <div style="margin-top: 35px">
    <v-row
      justify="center"
      :style="{ margin: '10px' }"
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="incluirBotoes.includes('EXPORTAR_EXCEL')"
            small
            elevation="0"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            v-on="on"
            @click="exportGrid('xlsx')"
          >
            <v-icon> table_view </v-icon>
          </v-btn>
        </template>
        <span style="color: #fff">{{
          this.$t("BotoesAcao.exportaExcel")
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="incluirBotoes.includes('EXPORTAR_PDF')"
            small
            elevation="0"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            v-on="on"
            @click="criaPDF"
          >
            <v-icon> picture_as_pdf </v-icon>
          </v-btn>
        </template>
        <span style="color: #fff">{{ this.$t("BotoesAcao.exportaPdf") }}</span>
      </v-tooltip>

      <!-- <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="incluirBotoes.includes('CARGA_POR_PLANILHA')"
            small
            elevation="0"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            v-on="on"
            @click="openBatch"
          >
            <v-icon> open_in_browser </v-icon>
          </v-btn>
        </template>
        <span style="color: #fff">{{
          this.$t("BotoesAcao.importaPlanilha")
        }}</span>
      </v-tooltip> -->
      <!-- <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="incluirBotoes.includes('BAIXAR_T')"
            small
            elevation="0"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            :href="tabelaModelo"
            :download="nomeTabela"
            v-on="on"
          >
            <v-icon>file_download</v-icon>
          </v-btn>
        </template>
        <span style="color: #fff">{{
          this.$t("BotoesAcao.baixarTemplate")
        }}</span>
      </v-tooltip> -->
      <div
        class="ml-3 mr-2 mt-0 mb-0"
        style="font-size: 18px"
      >
        <span> | </span>
      </div>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="incluirBotoes.includes('DELETAR')"
            small
            elevation="0"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            v-on="on"
            @click="apagar"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
        <span style="color: #fff">{{ this.$t("BotoesAcao.deletar") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="incluirBotoes.includes('EDITAR')"
            small
            elevation="0"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            v-on="on"
            @click="editarProduto"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
        <span style="color: #fff">{{ this.$t("BotoesAcao.editar") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="incluirBotoes.includes('SALVAR')"
            small
            elevation="0"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            v-on="on"
            @click="$emit('salvar')"
          >
            <v-icon> save </v-icon>
          </v-btn>
        </template>
        <span style="color: #fff">{{ this.$t("BotoesAcao.salvar") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="incluirBotoes.includes('NOVO')"
            small
            elevation="0"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            v-on="on"
            @click="novoProduto"
          >
            <v-icon> add </v-icon>
          </v-btn>
        </template>
        <span style="color: #fff">{{ this.$t("BotoesAcao.novo") }}</span>
      </v-tooltip>

      <!-- <div
        v-if="incluirBotoes.includes('IMPORTACOES')"
        class="ml-3 mr-2 mt-0 mb-0"
        style="font-size: 18px"
      >
        <span> | </span>
      </div>

      <v-dialog
        v-if="incluirBotoes.includes('IMPORTACOES')"
        v-model="dialog"
        width="800"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            elevation="0"
            class="tooltip"
            color="primary"
            v-bind="attrs"
            :style="{ marginLeft: '5px' }"
            v-on="on"
          >
            <v-icon> publish </v-icon>
            <span
              class="tooltiptext"
              style="display: flex; justify-content: center"
            >
              {{ $t("TabelaImportada.Dialog.importacoes") }}
            </span>
          </v-btn>
        </template>

        <v-card>
          <v-card-title
            class="text-h5 mb-2"
            style="display: flex; justify-content: start"
          >
            <v-icon class="mr-2">
              drive_file_move_outline
            </v-icon>
            {{ $t("TabelaImportada.Dialog.importacao") }}
            <v-spacer />
            <div style="display: fex; justify-content: end; width: 50px">
              <v-btn
                color="primary"
                style="font-size: 15px"
                text
                @click="dialog = false"
              >
                X
              </v-btn>
            </div>
          </v-card-title>
          <v-divider />
          <v-row
            style="width: 100%"
            class="mt-2"
          >
            <v-col
              style="display: flex; justify-content: center"
              class="ml-6"
            >
              <v-btn
                style="height: 200px; width: 100%"
                color="primary"
                @click="openBatch"
              >
                {{ $t("TabelaImportada.Dialog.inserir") }}
              </v-btn>
            </v-col>
            <v-col style="display: flex; justify-content: center">
              <v-btn
                style="height: 200px; width: 100%"
                color="primary"
                disabled
              >
                {{ $t("TabelaImportada.Dialog.atualizar") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row style="width: 100%">
            <v-card-text
              style="font-size: 20px; display: flex; justify-content: center"
            >
              {{ $t("TabelaImportada.Dialog.template") }}
            </v-card-text>
          </v-row>
          <v-row
            style="
              width: 100%;
              font-size: 20px;
              display: flex;
              justify-content: center;
            "
            class="mb-4 mt-2"
          >
            <v-btn
              color="#4f8a10"
              style="color: white"
              :href="tabelaModelo"
              :download="nomeTabela"
            >
              {{ $t("TabelaImportada.Dialog.btnDownload") }}
            </v-btn>
            <v-card-text
              class="ml-6"
              style="
                font-size: 15px;
                display: flex;
                justify-content: start;
                color: red;
              "
            >
              * {{ $t("TabelaImportada.Dialog.limite") }}
            </v-card-text>
          </v-row>
          <v-card-actions>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-row>
    <div>
      <cadastro-produto
        v-model="dialogEditar"
        :produtos="selecaoProdutos"
        :editando="editandoProdutosSelecionados"
        :campos="camposCadastrarEditarProduto"
        @novo="novoProd"
        @editado="editProd"
      />
      <product-batch-upload
        ref="batch"
        @produtos-importados="dialogImportados"
      />
      <dialogo-tabela-importada
        v-model="dialogImportDados"
        :errados="importsIncorretos"
        :corretos="importsCorretos"
        :avisos="importsAvisos"
        :duplicados="dadosDuplicados"
        :obrigatorios="obrigatoriosTabelaImportada"
        :excel="geradorExcel"
        :colunas="colunasTabelaImportada"
        @confirmar="dadosConfirmados"
      />
      <json-excel
        ref="tabelaBase"
        style="display: none"
        :data="geradorExcel"
        name="Tabela-base SaaSTEC Admin.xlsx"
      />
    </div>
  </div>
</template>

<script>
import methods from "./methods.js";
import cadastroProduto from "@/components/cadastroProduto";
import ProductBatchUpload from "@/components/ProductBatchUpload";
import dialogoTabelaImportada from "../dialogoTabelaImportada";
import JsonExcel from "vue-json-excel";
import XLSX from "xlsx";

export default {
  components: {
    cadastroProduto,
    ProductBatchUpload,
    dialogoTabelaImportada,
    JsonExcel,
  },

  props: {
    // Tabela modelo de exportação
    tabelaModelo: {
      type: String,
      required: false,
      default: "",
    },
    nomeTabela: {
      type: String,
      required: false,
      default: "",
    },
    // Tabela do wijmo flexgrid
    flexgrid: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    // Nome da aba para gerar o PDF
    nomeAba: {
      type: String,
      required: true,
      default: "",
    },
    // Dados da tabela atual
    dados: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    // Produtos selecionados na tabela para edição ou remoção
    produtosSelecionados: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    // Array com dados geradores da tabela base do excel
    geradorExcel: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    // Colunas dialogo tabela importada
    colunasTabelaImportada: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    // Dados obrigatórios no Excel para serem conferidos
    obrigatoriosTabelaImportada: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    // Campos para formação do formulário de cadastro/edição de produto
    camposCadastrarEditarProduto: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    //  Botoes que deseja incluir na pagina
    incluirBotoes: {
      type: Array,
      required: false,
      default: () => {
        return [
          "NOVO",
          "SALVAR",
          "EXPORTAR_EXCEL",
          "EXPORTAR_PDF",
          "BAIXAR_T",
          "EDITAR",
          "CARGA_POR_PLANILHA",
          "DELETAR",
          "IMPORTACOES",
        ];
      },
    },
  },
  data() {
    return {
      dialogEditar: false,
      selecaoProdutos: [],
      editandoProdutosSelecionados: false,
      importsCorretos: [],
      importsIncorretos: [],
      importsAvisos: [],
      dadosDuplicados: [],
      dialogImportDados: false,
      dialog: false,
    };
  },
  methods: {
    ...methods,
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 110px;
  height: 30px;
  background-color: #444;
  color: #fff;
  text-align: center;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 80px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  text-transform: capitalize;
  font-size: 13px;
  font-family: sans-serif;
  opacity: 0;
  transition: ease-in-out opacity 0.3s;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>