import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import moment from "moment";
import * as pdf from "@grapecity/wijmo.pdf";
import * as gridPdf from "@grapecity/wijmo.grid.pdf";
import * as logo from "../../../public/saastecapp.png";
import { forEach } from "lodash";

let nomeArquivo; // Nome do arquivo do excel exportado

export default {
  novoProduto() {
    this.selecaoProdutos = [];
    this.dialogEditar = true;
    this.editandoProdutosSelecionados = false;
  },
  editarProduto() {
    this.selecaoProdutos = [];
    if (this.produtosSelecionados) {
      this.produtosSelecionados.forEach((p) => {
        this.selecaoProdutos.push({
          ...p,
        });
      });
    }
    if (this.selecaoProdutos.length === 0) {
      this.notification().warn({ mensagem: "Selecione os itens para editar"});
    } else {
      this.dialogEditar = true;
      this.editandoProdutosSelecionados = true;
    }
  },
  novoProd(prod) {
    this.$emit("novo", prod);
  },
  editProd(prod) {
    this.$emit("editado", prod);
  },
  exportGrid(docType) {
    if (docType === "xlsx") {
      // create book with current view
      let book = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(this.flexgrid, {
        includeColumnHeaders: true,
        includeRowHeaders: true,
      });
      book.sheets[0].name = "PivotGrid";
      let date = moment()
        .locale("pt-br")
        .format("MMMM Do YYYY, h:mm:ss a");
      // save the book
      book.saveAsync(`${this.$route.name}${date}`);
    }
  },
  criaPDF() {
    const emissao = moment()
      .locale("pt-br")
      .format("DD/MM/YYYY HH:MM");
    const nome_arquivo = this.nomeAba.includes("Pesquisa")
      ? this.nomeAba.split(" -")[0] +
        ` - ${moment()
          .locale("pt-br")
          .format("l")}`
      : this.nomeAba;
    const vm = this;
    const doc = new pdf.PdfDocument({
      // Adiciona a logo em todas as páginas
      pageAdded: function(s) {
        s.header.drawImage(logo, 0, -15, {
          // left e top
          height: 30, // 500 altura da imagem
          width: 102, // 1024 largura da imagem
        });
        s.header.drawText(`Emissão: ` + emissao, 400, 0, {
          // left e top
          font: new pdf.PdfFont("helvetica", 8, "normal"),
        });
        s.header.drawText(`Total de registros: ${vm.dados.length}`, 400, 8, {
          // left e top
          font: new pdf.PdfFont("helvetica", 8, "normal"),
        });
      },

      header: {
        declarative: {
          text: `\t${nome_arquivo}`,
          font: new pdf.PdfFont("helvetica", 16, "normal", "bold"),
          brush: "#000000",
        },
        height: 50,
      },
      footer: {
        declarative: {
          // eslint-disable-next-line
          text: "\tPágina: &[Page] de &[Pages] \tSaaSTEC Admin  ",
          font: new pdf.PdfFont("helvetica", 8, "normal"),
          brush: "#000000",
        },
      },
      pageSettings: {
        layout: 0,
        size: pdf.PdfPageSize.A4,
        margins: {
          top: 40,
          left: 50,
          right: 20,
          bottom: 20,
        },
      },
      ended: async function(sender, args) {
        await pdf.saveBlob(args.blob, nome_arquivo + ".pdf");
      },
    });

    const settings = {
      styles: {
        cellStyle: {
          borderColor: "transparent",
        },
        altCellStyle: {},
        groupCellStyle: {},
        headerCellStyle: {
          backgroundColor: "#eaeaea",
        },
      },
    };

    gridPdf.FlexGridPdfConverter.draw(this.flexgrid, doc, 430, null, settings);

    // finish document
    doc.end();
  },
  openBatch() {
    //this.drawer = false
    // let reader = new FileReader();
    // reader.readAsArrayBuffer(e.target.files[0]);
    // let data = new Uint8Array(reader.result);
    // let wb = XLSX.read(data, { type: 'array' });
    // let htmlstr = XLSX.write(wb, { sheet: "sheet no1", type: 'binary', bookType: 'html' });
    // ('#wrapper')[0].innerHTML += htmlstr;
    this.$refs.batch.openUploadDrawer();
    setTimeout(() => {
      this.dialog ? (this.dialog = false) : null;
    }, 100);
  },
  validateFields(valoresColunas) {
    let campos = this.colunasTabelaImportada;
    campos.forEach((campo) => {
      if (campo.regex) {
        let regex = this.escolheRegex(campo.regex);
        regex = new RegExp(regex);

        let valorColuna = valoresColunas[campo.valor].toString();

        if (regex.test(valorColuna)) {
          return campo;
        }
        return null;
      }
    });
  },
  dialogImportados(prod, nome) {
    nomeArquivo = nome.split(".xlsx")[0];
    this.importsIncorretos = [];
    this.importsCorretos = [];
    this.importsAvisos = [];
    this.dadosDuplicados = [];
    let camposObrigatorios = this.obrigatoriosTabelaImportada;
    const vm = this;
    prod.forEach((p) => {
      let objTemErro = false;
      let cabecalhosFaltantes = [];
      this.validateFields(p);
      p["Vlr. venda"] > 10000 ? vm.importsAvisos.push(p) : null;
      this.aplicaMascara(p);
      camposObrigatorios.forEach((cab) => {
        if (!(cab in p)) {
          cabecalhosFaltantes.push(cab);
          objTemErro = true;
        }
      });
      if (objTemErro) {
        let obj = {
          ausencia: cabecalhosFaltantes,
          produto: p,
        };
        vm.importsIncorretos.push(obj);
      } else {
        vm.importsCorretos.push(p);
      }
    });
    this.validaCampos(this.importsCorretos);
    this.dialogImportDados = true;
  },
  validaCampos(corretos) {
    // encontra os valores iguais
    const semDuplicata = corretos
      .map((produto) => produto["SKU"])

      // encontra o index de cada objeto
      .map((item, i, final) => final.indexOf(item) === i && i)

      // remove os itens duplicados
      .filter((item) => corretos[item])
      .map((index) => corretos[index]);

    const comDuplicata = corretos
      .map((produto) => produto["SKU"])
      // encontra  index de cada objeto e encontra os itens duplicados
      .map((item, i, final) => final.indexOf(item) != i && i)
      .filter((item) => corretos[item])
      .map((index) => corretos[index]);

    this.dadosDuplicados = comDuplicata;
    this.importsCorretos = semDuplicata;
  },
  aplicaMascara(p) {
    if (p["Vlr. venda"]) {
      p["Vlr. venda"] = p["Vlr. venda"].toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    }
    if (p["Vlr. compra"]) {
      p["Vlr. compra"] = p["Vlr. compra"].toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    }
    if (p["SKU"]) {
      p["SKU"] = p["SKU"].toUpperCase();
    }
    if (p["Produto"]) {
      p["Produto"] = p["Produto"].toUpperCase();
    }
    if (p["Referência"]) {
      p["Referência"] = p["Referência"].toUpperCase();
    }
    if (p["Unidade"]) {
      p["Unidade"] = p["Unidade"].toUpperCase();
    }
  },
  dadosConfirmados(DC, DI) {
    this.$emit("corretos-incorretos", DC, DI, nomeArquivo);
  },
  apagar() {
    let dadosMap = [];
    this.dados.forEach((p) => {
      if (p.isSelected) {
        dadosMap.push(p);
      }
    });
    this.$emit("deletar", dadosMap);
  },
};
