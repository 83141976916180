
import dialogoLocaliza from '@/components/dialogoLocaliza/'
import { exemplo } from './exemplo'
export default {
  components: { dialogoLocaliza },
  props: {
    produtos: {
      type: Array,
      require: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => {return exemplo},
    },
    markup: {
      type: Boolean,
      required: false,
      default: false,
    },
    chaveVlCompra:{
      type: String,
      required: false,
    },
    chaveVlVenda:{
      type: String,
      required: false,
    },
  },
  data: () => {
    return {
      // form elements
      add: true,
      mudancas: {},
      flagMarkup: false,
      vlCompraCheckbox: false,
      vlVendaCheckbox: false,
      vlVendaBaseCompra: false,
      dialogoFerramentas: false,
      modelMarkup: "",
      //verificadores
      percentagemMarkup: false, 
      bkProduto: [],
      panel: false,
      e1: 1,
      showInfo: false,
      // novoForm
      linhas: [{
        select: '',
        textField: '',
      }],

      //Multifilter
      sp: '',
      nome: '',
      usarFiltro: false, 
      adicionar: false,
      opcoesTipo: [],
      indexAtual: 0,
    }
  },
  watch: {
    vlVendaBaseCompra() {
      if(this.vlVendaBaseCompra) {
        this.vlCompraCheckbox = false
        this.vlVendaCheckbox = false
      }
    },
    vlCompraCheckbox() {
      if(this.vlCompraCheckbox) {
        this.vlVendaBaseCompra = false
      }
    },
    vlVendaCheckbox() {
      if(this.vlVendaCheckbox) {
        this.vlVendaBaseCompra = false
      }
    },
    linhas: {
      deep: true,
      handler(){
        this.items.forEach(i => {
          let existente = false
          this.linhas.forEach(l => {
            if(!existente){        
              if(l.select.nome === i.value.nome){
                i.disabled = true
                existente = true
              }
              else{
                i.disabled = false
              }
            }
          })
        })
      },
    },
  },
  mounted() {
    this.propMarkup()
    this.getAutocompletes()
    this.addDisabled()
    this.bkProduto = this.produtos
  },
  methods: { 
    camposDinheiro(select, valor) {
      valor = parseFloat(valor)
      valor = this.formatCurrency(valor)
      if (select == 'Vl. Compra') {
        this.vlCompra = valor
        this.mudancas['Vl. compra'] = valor
      } else {
        this.vlVenda = valor
        this.mudancas['Vl. venda'] = valor
      }
      return valor
    },
    addDisabled(){
      // Adiciona o atributo "disabled" em todas as opções
      this.items.forEach(i => {
        i.disabled = false 
      })
    },

    calculaMarkup(produto) {
      const valorMarkup = {};
      if(this.modelMarkup !== "") {
        if(this.vlCompraCheckbox) {
          valorMarkup[this.chaveVlCompra] =  this.tipoMarkup(produto[this.chaveVlCompra])
        }
        if(this.vlVendaCheckbox) {
          valorMarkup[this.chaveVlVenda] = this.tipoMarkup(produto[this.chaveVlVenda])
        }
        if(this.vlVendaBaseCompra) {
          valorMarkup[this.chaveVlVenda] = this.tipoMarkup(produto[this.chaveVlCompra])
        }
      } 
      return valorMarkup
    },
    tipoMarkup(produto){
      let resultado = null
      if(this.add){ // Se for para adicionar mais valor
        if(this.percentagemMarkup){ // Confere se é percentagem
          resultado = this.formatCurrency(parseFloat(this.unmaskCurrency(produto)) + 
            (parseFloat(this.unmaskCurrency(produto)) * (parseFloat(this.modelMarkup) / 100)))
        }
        else{ // Confere se é valor monetário
          resultado = this.formatCurrency(parseFloat(this.unmaskCurrency(produto)) + parseFloat(this.modelMarkup))
        }
      }
      else { // Se for para subtrair valor
        if(this.percentagemMarkup){ // Confere se é percentagem
          resultado = this.formatCurrency(parseFloat(this.unmaskCurrency(produto)) - 
            (parseFloat(this.unmaskCurrency(produto)) * (parseFloat(this.modelMarkup) / 100)))
        }
        else{ // Confere se é valor monetário
          resultado = this.formatCurrency(parseFloat(this.unmaskCurrency(produto)) - parseFloat(this.modelMarkup))
        }
      }
      return resultado
    },
    alterarProdutos() {
      const edicaoMarkup = this.linhas.filter(l => l.select.type === "markup").length
      if(this.markup && !!edicaoMarkup){
        this.mudancas["Itens com markup"] = this.produtos.map(f => {
          /* let confere =  this.calculaMarkup(f) */
          if(this.calculaMarkup(f)[this.chaveVlCompra]){
            f[this.chaveVlCompra] = this.calculaMarkup(f)[this.chaveVlCompra]
          }
          if(this.calculaMarkup(f)[this.chaveVlVenda]){
            f[this.chaveVlVenda] = this.calculaMarkup(f)[this.chaveVlVenda]
          }
          return f
        })
      }
      this.$emit('alteracoes', this.mudancas)
    },
    abreDialogoLocaliza(obj, index){
      this.indexAtual = index
      this.usarFiltro = obj.filtro
      this.sp         = obj.SP
      this.opcoesTipo = obj.opcoesTipo
      this.nome       = obj.nome
      this.adicionar  = obj.adicionar
      this.dialogoFerramentas = true
    },
    propMarkup(){
      const hasMarkup = this.items.filter(i => i.text === 'Markup').length > 0
      if(this.markup && !hasMarkup){
        const opcaoMarkup = {
          text: 'Markup',
          value: {
            type: "markup",
            SP: false,
            opcoesTipo: false,
            nome: 'markup',
            adicionar: false,
            filtro: false,
            itens: false,
          },
        }
        this.items.unshift(opcaoMarkup)
      }
    },
    getValue(e){
      this.mudancas[e.select.nome] = e.textField
    },
    getMulfilterValue(e) {
      const linha = this.linhas[this.indexAtual]
      this.mudancas[this.linhas[this.indexAtual].select.nome] = e
      linha.textField = e[linha.select.objKey]
    },
    removeLinha(linha, i){
      if(linha.select.nome in this.mudancas){
        delete this.mudancas[linha.select.nome]
      }
      else if(linha.select.nome === 'markup'){
        delete this.mudancas["Itens com markup"]
      }
      this.linhas.splice(i, 1)
    }},
}