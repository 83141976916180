import * as wjcCore from "@grapecity/wijmo";
import { Selector } from "@grapecity/wijmo.grid.selector";
import * as wjcGrid from "@grapecity/wijmo.grid";

export default {
  onloadedRows(grid) {
    this.tabela = grid;
    this.validarDados();
  },
  initializeGrid(flex) {
    this.selector = new Selector(flex, {
      itemChecked: () => {
        this.selectedItems = flex.rows
          .filter((r) => r.isSelected)
          .map((p) => {
            return {
              ...p,
              dataItem: {
                ...p.dataItem,
                isSelected: p.isSelected,
              },
            };
          });

        this.data = this.dados.map((p) => {
          // A próxima linha força com que o ID do objeto seja o primeiro registro
          const selected = !!this.selectedItems.find(
            (f) => f.dataItem["SKU"] === p["SKU"],
          );
          p.isSelected = selected;
          return p;
        });
      },
    });
  },
  validarDados() {
    /* Campos obrigatórios */
    var dummyCabecalhos = this.obrigatorios;
    /* Separa o "this" para ser acessado no laço "for" */
    const vm = this;
    for (let i = 0; i < this.tabela.rows.length; i++) {
      let comentario = {};
      var row = vm.tabela.rows[i];
      var item = row.dataItem;
      /* Confere se o objeto possui os atributos do array */
      dummyCabecalhos.forEach((d) => {
        /* Se não tiver, adiciona o comentário */
        if (!(d in item)) {
          comentario[d] = "Campo obrigatório";
        } else {
          comentario[d] = null;
        }
      });
      /* Adiciona o comentário ao objeto analisado */
      if (Object.keys(comentario).length) {
        item.notes = comentario;
      }
    }

    let tooltip = new wjcCore.Tooltip();
    this.tabela.formatItem.addHandler((s, e) => {
      /* Caso a célula analisada não ser as células com dados */
      if (e.panel.cellType != wjcGrid.CellType.Cell) {
        return;
      }
      if (e.panel == s.cells) {
        /* Guarda o objeto na variavel item */
        let item = s.rows[e.row].dataItem,
          /* Percorre as chaves do item.notes */
          binding = s.columns[e.col].binding,
          /* Guarda o comentário na variável note */
          note = item.notes ? item.notes[binding] : null;
        /* Adiciona o pequeno triângulo vermelho */
        wjcCore.toggleClass(e.cell, "wj-has-notes", note != null);
        if (note != null) {
          /* Adiciona o quadrado do comentário */
          tooltip.setTooltip(e.cell, "<b>Aviso:</b><br/>" + note);
          /* Colore a célula em vermelho */
          wjcCore.addClass(e.cell, "dados-faltando");
        }
      }
    });
    this.errados.forEach((e, i) => {
      let isDadoCorreto = true;
      dummyCabecalhos.forEach((d) => {
        if (e.produto.notes[d] !== null) isDadoCorreto = false;
      });
      if (isDadoCorreto) {
        this.corretos.push(e.produto);
        this.errados.splice(i, 1);
        this.dados = this.errados;
      }
    });
  },

  close() {
    this.$emit("change", false);
  },
  instalaTabela() {
    this.$refs.tabelaBase.$el.click();
  },
  insereDadosCorretos(corretos, incorretos, avisos, duplicados) {
    corretos.forEach((p) => {
      this.removeMascara(p);
    });
    corretos.map((dados) => {
      if (dados.isSelected) {
        this.corretoSelecionado.push(dados);
      }
    });
    corretos = null;
    corretos = this.corretoSelecionado;
    this.$emit("confirmar", corretos, incorretos, avisos, duplicados);
    this.$emit("change", false);
    corretos = null;
    incorretos = null;
    avisos = null;
    duplicados = null;
  },
  removeMascara(p) {
    if (p["Vlr. venda"]) {
      p["Vlr. venda"] = p["Vlr. venda"].replace(/\.|R|\$/g, "");
      p["Vlr. venda"] = parseInt(p["Vlr. venda"]);
    }
    if (p["Vlr. compra"]) {
      p["Vlr. compra"] = p["Vlr. compra"].replace(/\.|R|\$/g, "");
      p["Vlr. compra"] = parseInt(p["Vlr. compra"]);
    }
  },
  verificaTotais() {
    if (this.errados.length > 0) {
      this.dados = this.errados.map((valoresIncorretos) => {
        return valoresIncorretos.produtos;
      });
    } else {
      this.dados = this.corretos.map((valoresCorretos) => {
        return valoresCorretos;
      });
    }
    if (this.avisos.length > 0 && this.verificaTabs == "Avisos") {
      this.dados = this.avisos.map((avisos) => {
        return avisos;
      });
    }
    if (this.duplicados.length > 0 && this.verificaTabs == "Resumo") {
      this.dados = this.duplicados.map((duplicados) => {
        return duplicados;
      });
    }
    if (
      this.errados.length == 0 &&
      this.corretos.length == 0 &&
      this.avisos.length == 0
    ) {
      this.verificaTabela = false;
    } else {
      this.verificaTabela = true;
    }
  },
  verificaParametros(verificador, corretos, errados) {
    let quantidadeDados = corretos + errados;
    if (quantidadeDados > 10000 || verificador == false) {
      return;
    }
  },
  mensagens(TamanhoCorretos, TamanhoAvisos, TamanhoErrados, nomeTab) {
    if (TamanhoErrados > 0 && nomeTab != "Resumo") {
      if (nomeTab === "Errados") {
        this.mensagem = `${this.$t("TabelaImportada.mensagens.incorretos1")}`;
      } else {
        this.mensagem = `${this.$t("TabelaImportada.mensagens.incorretos2")}`;
      }
    } else if (
      TamanhoErrados == 0 &&
      TamanhoAvisos == 0 &&
      TamanhoCorretos > 0 &&
      nomeTab != "Resumo"
    ) {
      if (nomeTab === "Corretos") {
        this.mensagem = `${this.$t("TabelaImportada.mensagens.corretos1")}`;
      } else {
        this.mensagem = `${this.$t("TabelaImportada.mensagens.corretos2")}`;
      }
    } else if (
      TamanhoAvisos != 0 &&
      TamanhoErrados == 0 &&
      nomeTab != "Resumo"
    ) {
      if (nomeTab === "Avisos") {
        this.mensagem = `${this.$t("TabelaImportada.mensagens.avisos1")}`;
      } else {
        this.mensagem =
          `${this.$t("TabelaImportada.mensagens.avisos2")}` +
          TamanhoAvisos +
          `${this.$t("TabelaImportada.mensagens.avisos3")}`;
      }
    } else if (nomeTab === "Resumo") {
      if (this.duplicados.length > 0) {
        this.mensagem = `${this.$t("TabelaImportada.mensagens.duplicados1")}`;
      } else {
        this.mensagem = `${this.$t("TabelaImportada.mensagens.duplicados2")}`;
      }
    }
  },
};
