var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"35px"}},[_c('v-row',{style:({ margin: '10px' }),attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.incluirBotoes.includes('EXPORTAR_EXCEL'))?_c('v-btn',_vm._g(_vm._b({style:({ marginLeft: '5px' }),attrs:{"small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.exportGrid('xlsx')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" table_view ")])],1):_vm._e()]}}])},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(this.$t("BotoesAcao.exportaExcel")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.incluirBotoes.includes('EXPORTAR_PDF'))?_c('v-btn',_vm._g(_vm._b({style:({ marginLeft: '5px' }),attrs:{"small":"","elevation":"0","color":"primary"},on:{"click":_vm.criaPDF}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" picture_as_pdf ")])],1):_vm._e()]}}])},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(this.$t("BotoesAcao.exportaPdf")))])]),_c('div',{staticClass:"ml-3 mr-2 mt-0 mb-0",staticStyle:{"font-size":"18px"}},[_c('span',[_vm._v(" | ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.incluirBotoes.includes('DELETAR'))?_c('v-btn',_vm._g(_vm._b({style:({ marginLeft: '5px' }),attrs:{"small":"","elevation":"0","color":"primary"},on:{"click":_vm.apagar}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}])},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(this.$t("BotoesAcao.deletar")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.incluirBotoes.includes('EDITAR'))?_c('v-btn',_vm._g(_vm._b({style:({ marginLeft: '5px' }),attrs:{"small":"","elevation":"0","color":"primary"},on:{"click":_vm.editarProduto}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}])},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(this.$t("BotoesAcao.editar")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.incluirBotoes.includes('SALVAR'))?_c('v-btn',_vm._g(_vm._b({style:({ marginLeft: '5px' }),attrs:{"small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.$emit('salvar')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" save ")])],1):_vm._e()]}}])},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(this.$t("BotoesAcao.salvar")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.incluirBotoes.includes('NOVO'))?_c('v-btn',_vm._g(_vm._b({style:({ marginLeft: '5px' }),attrs:{"small":"","elevation":"0","color":"primary"},on:{"click":_vm.novoProduto}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" add ")])],1):_vm._e()]}}])},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(this.$t("BotoesAcao.novo")))])])],1),_c('div',[_c('cadastro-produto',{attrs:{"produtos":_vm.selecaoProdutos,"editando":_vm.editandoProdutosSelecionados,"campos":_vm.camposCadastrarEditarProduto},on:{"novo":_vm.novoProd,"editado":_vm.editProd},model:{value:(_vm.dialogEditar),callback:function ($$v) {_vm.dialogEditar=$$v},expression:"dialogEditar"}}),_c('product-batch-upload',{ref:"batch",on:{"produtos-importados":_vm.dialogImportados}}),_c('dialogo-tabela-importada',{attrs:{"errados":_vm.importsIncorretos,"corretos":_vm.importsCorretos,"avisos":_vm.importsAvisos,"duplicados":_vm.dadosDuplicados,"obrigatorios":_vm.obrigatoriosTabelaImportada,"excel":_vm.geradorExcel,"colunas":_vm.colunasTabelaImportada},on:{"confirmar":_vm.dadosConfirmados},model:{value:(_vm.dialogImportDados),callback:function ($$v) {_vm.dialogImportDados=$$v},expression:"dialogImportDados"}}),_c('json-excel',{ref:"tabelaBase",staticStyle:{"display":"none"},attrs:{"data":_vm.geradorExcel,"name":"Tabela-base SaaSTEC Admin.xlsx"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }