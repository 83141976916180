var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-navigation-drawer',{attrs:{"temporary":"","fixed":"","right":"","width":_vm.$vssWidth},model:{value:(_vm.openDrawer),callback:function ($$v) {_vm.openDrawer=$$v},expression:"openDrawer"}},[_c('v-file-input',{key:_vm.keyFileInput,style:({ display: 'none' }),attrs:{"id":"import","accept":".xlsx","multiple":""},on:{"change":_vm.trataImportarArquivoBotao}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeUploadDrawer}},[_c('v-icon',[_vm._v(" close ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{style:({
            margin: 0,
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '330px',
            padding: '20px',
          })},[_vm._v(" Faça o upload de um arquivo compactado .zip contendo um arquivo Excel e uma pasta imagens com as informações e imagens dos produtos. ")]),_c('v-row',{style:({
            margin: 0,
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '330px',
          })},[_c('v-btn',{on:{"click":_vm.importarAqruivo}},[_vm._v(" Importar arquivo ")])],1),_c('div',{class:_vm.uploadAreaClass,on:{"dragstart":_vm.fileDragStart,"dragover":_vm.fileDragOver,"dragleave":_vm.fileDragLeave,"drop":_vm.fileDrop}},[(!_vm.showFilesList)?_c('div',{staticClass:"center-items"},[_c('v-icon',{attrs:{"size":"48"}},[_vm._v(" upload_file ")]),_c('span',[_vm._v(" Arraste e solte o arquivo ")])],1):_vm._e(),(_vm.showFilesList)?_c('div',_vm._l((_vm.files),function(file,i){return _c('v-card',{key:file.key + i,attrs:{"loading":file.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(file.name)+" "),_c('v-spacer'),(!file.loading)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" check ")]):(file.loadError)?_c('v-icon',{attrs:{"color":"red darken-3"}},[_vm._v(" close ")]):_vm._e(),_c('v-icon',{attrs:{"color":"grey"},on:{"click":function($event){return _vm.removerArquivo(i)}}},[_vm._v(" close ")])],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.readableSize(file.size))+" ")])],1)}),1):_vm._e()]),_c('div',{staticClass:"center-items",staticStyle:{"margin-top":"-150px"}},[_c('v-btn',{on:{"click":_vm.enviarArquivo}},[_vm._v(" Finalizar ")])],1)],1),(_vm.showFilesList)?_c('v-col',{attrs:{"cols":"8"}},[(_vm.fileLoader)?_c('div',{staticClass:"loaderArquivo"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('div',[_c('v-pagination',{attrs:{"length":_vm.paginationLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.prods},scopedSlots:_vm._u([{key:"item.valorCompra",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.valorCompra))+" ")]}},{key:"item.valorVenda",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.valorVenda))+" ")]}}],null,true)})],1)]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }