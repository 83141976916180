<template>
  <v-container>
    <v-navigation-drawer
      v-model="openDrawer"
      temporary
      fixed
      right
      :width="$vssWidth"
    >
      <v-file-input
        id="import"
        :key="keyFileInput"
        accept=".xlsx"
        multiple
        :style="{ display: 'none' }"
        @change="trataImportarArquivoBotao"
      />
      <v-btn
        icon
        @click="closeUploadDrawer"
      >
        <v-icon> close </v-icon>
      </v-btn>
      <v-row>
        <v-col cols="3">
          <v-row
            :style="{
              margin: 0,
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '330px',
              padding: '20px',
            }"
          >
            Faça o upload de um arquivo compactado .zip contendo um arquivo
            Excel e uma pasta imagens com as informações e imagens dos produtos.
          </v-row>

          <v-row
            :style="{
              margin: 0,
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '330px',
            }"
          >
            <v-btn @click="importarAqruivo">
              Importar arquivo
            </v-btn>
          </v-row>
          <div
            :class="uploadAreaClass"
            @dragstart="fileDragStart"
            @dragover="fileDragOver"
            @dragleave="fileDragLeave"
            @drop="fileDrop"
          >
            <div
              v-if="!showFilesList"
              class="center-items"
            >
              <v-icon size="48">
                upload_file
              </v-icon>
              <span> Arraste e solte o arquivo </span>
            </div>
            <div v-if="showFilesList">
              <v-card
                v-for="(file, i) in files"
                :key="file.key + i"
                :loading="file.loading"
              >
                <v-card-title>
                  {{ file.name }}
                  <v-spacer />
                  <v-icon
                    v-if="!file.loading"
                    color="green"
                  >
                    check
                  </v-icon>
                  <v-icon
                    v-else-if="file.loadError"
                    color="red darken-3"
                  >
                    close
                  </v-icon>
                  <v-icon
                    color="grey"
                    @click="removerArquivo(i)"
                  >
                    close
                  </v-icon>
                </v-card-title>
                <v-card-subtitle>
                  {{ readableSize(file.size) }}
                </v-card-subtitle>
              </v-card>
            </div>
          </div>
          <div
            style="margin-top: -150px"
            class="center-items"
          >
            <v-btn @click="enviarArquivo">
              Finalizar
            </v-btn>
          </div>
        </v-col>

        <v-col
          v-if="showFilesList"
          cols="8"
        >
          <div
            v-if="fileLoader"
            class="loaderArquivo"
          >
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
          <div v-else>
            <v-pagination
              v-model="page"
              :length="paginationLength"
            />

            <v-data-table
              :headers="headers"
              :items="prods"
            >
              <template #[`item.valorCompra`]="{ item }">
                {{ formatCurrency(item.valorCompra) }}
              </template>
              <template #[`item.valorVenda`]="{ item }">
                {{ formatCurrency(item.valorVenda) }}
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import methods from "./methods";

export default {
  data() {
    return {
      abrindoImg: false,
      imgDialog: false,
      imgProdSelecionado: "",
      keyFileInput: 0,

      headers: [
        {
          text: "Nome do produto",
          value: "nomeProduto",
        },
        {
          text: "SKU",
          value: "sku",
        },
        {
          text: "Situação",
          value: "situacao",
        },
        {
          text: "Grupo",
          value: "grupo",
        },
        {
          text: "Departamento",
          value: "departamento",
        },
        {
          text: "Seção",
          value: "secao",
        },
        {
          text: "Coleção",
          value: "colecao",
        },
        {
          text: "Valor de compra",
          value: "valorCompra",
        },
        {
          text: "Valor de venda",
          value: "valorVenda",
        },
        {
          text: "Imagens",
          value: "imagens",
        },
      ],

      prods: [],
      openDrawer: false,
      showFilesList: false,
      files: [],
      page: 1,
      paginationLength: 0,
      reajusteTamanhoTrataProduto: false,
      drawerWidth: 350,
      uploadAreaClass: {
        "upload-area": true,
        hover: false,
      },
      fileLoader: false,
    };
  },
  watch: {
    openDrawer(newValue) {
      if (!newValue) {
        this.drawerWidth = 350;
        this.prods = [];
        this.files = [];
        this.reajusteTamanhoTrataProduto = false;
        this.deleteItem("cargaProdutosPaginacao");
        this.page = 1;
        this.paginationLength = 0;
      }
    },
    page() {
      this.changePageCargaProdutosPagination();
    },
  },
  beforeDestroy() {
    this.deleteItem("cargaProdutosPaginacao");
  },
  methods: {
    ...methods,
  },
};
</script>

<style scoped>
.upload-area {
  border: 2px solid #888;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 20px;
  min-height: 450px;
  max-width: 330px;

  transition: all 0.5s ease;
}

.hover {
  border-color: #f46434;
  margin: 10px;
  transition: all 0.5s ease;
}

.center-items {
  min-height: 450px;
  max-width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loaderArquivo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
span {
  color: rgb(105, 105, 105);
}
</style>