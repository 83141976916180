<template>
  <v-dialog
    v-model="dialogo"
    :width="verificaTabela ? 1300 : 800"
    persistent
  >
    <v-card v-if="verificaTabela && corretos.length + errados.length <= 5000">
      <v-container>
        <v-card-title
          v-if="errados.length > 0"
          style="font-weight: bold; color: #d8000c"
        >
          <v-icon style="margin-right: 5px; color: #d8000c">
            error_outline
          </v-icon>
          Erro
        </v-card-title>
        <v-card-title
          v-if="avisos.length > 0"
          style="font-weight: bold; color: #ff8800"
        >
          <v-icon style="margin-right: 5px; color: #ff8800">
            warning_amber
          </v-icon>
          Aviso
        </v-card-title>
        <v-card-title
          v-if="avisos.length == 0 && errados.length == 0"
          style="font-weight: bold; color: #4f8a10"
        >
          <v-icon style="margin-right: 5px; color: #4f8a10">
            check_circle
          </v-icon>
          Sucesso
        </v-card-title>
        <v-card-text
          v-bind="
            mensagens(
              corretos.length,
              avisos.length,
              errados.length,
              verificaTabs
            )
          "
        >
          {{ mensagem }}
        </v-card-text>
        <v-tabs
          v-model="tab"
          class="tabPrincipal"
        >
          <v-tab
            class="tabCorretos"
            @click="verificaTabs = 'Corretos'"
          >
            {{ $t("TabelaImportada.corretos") }} ({{ corretos.length }})
          </v-tab>
          <v-tab
            class="tabAvisos"
            @click="verificaTabs = 'Avisos'"
          >
            {{ $t("TabelaImportada.avisos") }} ({{ avisos.length }})
          </v-tab>
          <v-tab
            class="tabErrados"
            @click="verificaTabs = 'Errados'"
          >
            {{ $t("TabelaImportada.errados") }} ({{ errados.length }})
          </v-tab>
          <v-tab
            class="tabResumo"
            @click="verificaTabs = 'Resumo'"
          >
            {{ $t("TabelaImportada.resumo") }}
          </v-tab>
        </v-tabs>
        <v-divider class="mb-2" />
        <div v-if="verificaTabs === 'Resumo'">
          <v-row class="mb-2 mt-0">
            <v-col
              cols="12"
              sm="3"
            >
              <v-card class="primary">
                <v-card-title class="tituloCard">
                  {{ $t("TabelaImportada.corretos") }}
                </v-card-title>
                <span class="spanTitulo">
                  {{ corretos.length }}
                </span>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-card class="primary">
                <v-card-title class="tituloCard">
                  {{ $t("TabelaImportada.avisos") }}
                </v-card-title>
                <span class="spanTitulo">
                  {{ avisos.length }}
                </span>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-card class="primary">
                <v-card-title class="tituloCard">
                  {{ $t("TabelaImportada.errados") }}
                </v-card-title>
                <span class="spanTitulo">
                  {{ errados.length }}
                </span>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-card class="primary">
                <v-card-title class="tituloCard">
                  {{ $t("TabelaImportada.duplicados") }}
                </v-card-title>
                <span class="spanTitulo">
                  {{ duplicados.length }}
                </span>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-divider v-if="verificaTabs === 'Resumo'" />
        <div style="background-color: rgba(0, 0, 0, 0)">
          <wj-group-panel
            class="mt-0"
            style="display: block; background-color: rgba(0, 0, 0, 0)"
            :grid="flex"
            :placeholder="this.$t('ItensTabela.arrasteAqui')"
          />
          <wj-flex-grid
            :key="keyFlexGrid"
            :items-source="dados"
            :initialized="initializeGrid"
            :loaded-rows="onloadedRows"
            style="max-height: 550px"
          >
            <div
              v-for="(coluna, i) in colunas"
              :key="`coluna-${i}`"
            >
              <wj-flex-grid-column
                v-if="coluna.cabecalho === 'Im. prod'"
                :key="tab"
                :header="coluna.cabecalho"
                :binding="coluna.valor"
                :width="coluna.larg"
                :cell-template="tplImage"
                css-class="cell-img"
              />
              <wj-flex-grid-column
                v-else
                :header="coluna.cabecalho"
                :binding="coluna.valor"
                :width="coluna.larg"
                :format="coluna.format"
                :align="coluna.align"
              />
            </div>
          </wj-flex-grid>
        </div>
        <div class="botoesOpcoes">
          <v-btn
            color="error"
            style="margin: 4px"
            @click="close"
          >
            {{ $t("TabelaImportada.cancelar") }}
          </v-btn>
          <v-btn
            v-if="verificaTabs != 'Corretos' && verificaTabs != 'Resumo'"
            color="primary"
            style="margin: 4px"
            @click="validarDados"
          >
            {{ $t("TabelaImportada.corrigir") }}
          </v-btn>
          <v-btn
            v-if="verificaTabs == 'Corretos'"
            dark
            color="green darken-1"
            style="margin: 4px"
            @click="insereDadosCorretos(corretos, errados)"
          >
            {{ $t("TabelaImportada.confirmar") }}
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <v-card
      v-if="verificaTabela == false || corretos.length + errados.length > 5000"
    >
      <v-container>
        <v-card-title
          style="
            display: flex;
            justify-content: center;
            font-weight: bold;
            color: #d8000c;
          "
        >
          <v-icon style="margin-right: 5px; color: #d8000c">
            error_outline
          </v-icon>
          {{ $t("TabelaImportada.Dialog.aviso") }}
        </v-card-title>
        <v-card-text
          style="display: flex; justify-content: center; font-weight: bold"
        >
          {{ $t("TabelaImportada.Dialog.inconsistencia") }}
        </v-card-text>
        <div style="display: flex; justify-content: center">
          <v-btn
            color="error"
            style="margin: 4px"
            @click="close"
          >
            {{ $t("TabelaImportada.Dialog.fechar") }}
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <!-- <json-excel
      ref="tabelaBase"
      style="display: none"
      :data="excel"
      name="Tabela-base SaaSTEC Admin"
    /> -->
  </v-dialog>
</template>

<script>
import methods from "./methods";
// import JsonExcel from "vue-json-excel";

// Imports wijmo
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.touch"; // support drag/drop on touch devices
import * as wjcGrid from "@grapecity/wijmo.grid";
import "@grapecity/wijmo.vue2.grid";
import "@grapecity/wijmo.vue2.grid.filter";
import "@grapecity/wijmo";
import "@grapecity/wijmo.grid.filter";
import "@grapecity/wijmo.vue2.grid.grouppanel";
import "@grapecity/wijmo.input";
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
export default {
  // components: { JsonExcel },
  model: {
    prop: "dialogo",
    event: "change",
  },
  props: {
    dialogo: {
      type: Boolean,
      default: false,
    },
    corretos: {
      type: Array,
      required: true,
    },
    errados: {
      type: Array,
      required: true,
    },
    avisos: {
      type: Array,
      required: true,
    },
    duplicados: {
      type: Array,
      required: true,
    },
    excel: {
      type: Array,
      required: true,
    },
    colunas: {
      type: Array,
      required: true,
    },
    obrigatorios: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      verificaTabela: true,
      mensagemTabs: "",
      mensagem: "",
      selector: null,
      wjcGrid,
      verificaTabs: "",
      dados: [],
      flex: {},
      corretoSelecionado: [],
      selectedItems: [],
      keyFlexGrid: 0,
      tplImage: CellMaker.makeImage({
        label: "Produto sem imagem",
        // eslint-disable-next-line
        click: (e, ctx) => {
          this.abreDialog = true;
          this.imgProdSelecionado = ctx.value;
        },
      }),
      tabela: null,
      tab: 0,
    };
  },
  watch: {
    dialogo: {
      handler() {
        this.verificaTotais();
      },
    },
    mensagem: {
      handler() {
        this.mensagens();
      },
    },
    tab: {
      handler() {
        if (this.tab === 0) {
          this.dados = this.corretos.map((e) => {
            return e;
          });
        } else if (this.tab === 1) {
          this.dados = this.avisos.map((e) => {
            return e.produto;
          });
        } else if (this.tab === 2) {
          this.dados = this.errados.map((e) => {
            return e.produto;
          });
        } else if (this.tab === 3) {
          this.dados = this.duplicados.map((e) => {
            return e;
          });
        }
        this.keyFlexGrid++;
      },
    },
  },
  methods: {
    ...methods,
  },
};
</script>

<style lang="scss" src="./style.scss" />